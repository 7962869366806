import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import { device, getBackgroundColor } from "../components/GlobalStyles"
import Layout from "../components/layout/layout"
import SEO from "../components/SEO"
import Title from "../components/shared/Title/Title"
import { ContactFormContext } from "../store/contact-form-ref-context"

const Wrapper = styled.div<{ color: { bg: string; text: string } }>`
  display: grid;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  align-content: center;
  justify-items: center;
  padding: 50px 60px;
  background-color: ${(props) => props.color.bg};
  box-sizing: border-box;
  color: ${(props) => props.color.text};

  @media ${device.tablet} {
    padding: 80px 0;
  }

  .logo {
    width: 60px;

    @media ${device.tablet} {
      width: auto;
    }
  }
`

const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  max-width: 638px;
  justify-items: center;
`

const StyledTitle = styled(Title)`
  margin: 50px 0 50px 0;
`

const NotFoundPage = ({ location }: { location: Location }) => {
  const contactFormSection = React.useRef(null)
  return (
    <ContactFormContext.Provider
      value={{
        sectionRef: contactFormSection,
      }}
    >
      <Layout location={location}>
        <SEO title={"WOJS przeprowadzki"} description={""} />
        <Wrapper color={getBackgroundColor("dark")}>
          <Content>
            <StaticImage
              src="./../images/logo-white.png"
              alt="logo"
              loading="eager"
              placeholder="none"
              objectFit="contain"
              width={115}
              className="logo"
            />
            <StyledTitle level="h2">Strona nie istnieje</StyledTitle>
          </Content>
        </Wrapper>
      </Layout>
    </ContactFormContext.Provider>
  )
}

export default NotFoundPage
